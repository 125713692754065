<template>
  <div class="pt-6 pb-3 text-center">
    <p>
      Suite à un problème technique, certaines fonctionnalités de notre site sont actuellement
      indisponibles.<br />
      Nous travaillons activement à leur rétablissement.<br />
      Nous vous invitons à revenir à partir de la semaine prochaine pour profiter pleinement de nos
      services.<br />
      Merci de votre compréhension.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "ProductTempUnavailableModal",

  emits: ["updateSize"],

  setup(props, { emit }) {
    onMounted(() => {
      emit("updateSize", "max");
    });
  },
});
</script>
