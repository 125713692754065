import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-6 pb-3 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("p", null, [
      _createTextVNode(" Suite à un problème technique, certaines fonctionnalités de notre site sont actuellement indisponibles."),
      _createElementVNode("br"),
      _createTextVNode(" Nous travaillons activement à leur rétablissement."),
      _createElementVNode("br"),
      _createTextVNode(" Nous vous invitons à revenir à partir de la semaine prochaine pour profiter pleinement de nos services."),
      _createElementVNode("br"),
      _createTextVNode(" Merci de votre compréhension. ")
    ], -1)
  ])))
}