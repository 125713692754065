<template>
  <NModal
    id="modal-wrapper"
    ref="modal"
    :size="initialValues.size"
    :visible="displayModal"
    :class="''"
    @close="
      () => {
        close();
      }
    "
  >
    <component
      :is="`${currentModal}Modal`"
      :id="`${currentModal}Modal`"
      :ref="childrenRef"
      data-cy="modal-content"
      @update-size="updateSize"
    />
  </NModal>
</template>

<script lang="ts">
import { reactive, defineComponent } from "vue";
import useModal from "@/composables/useModal";
/* eslint-disable */
// @ts-ignore
import NModal from "@team-uep/n-modal";
/* eslint-enable */
import RealisationModal from "./RealisationModal.vue";
import IubendaPrivacyPolicyModal from "./IubendaPrivacyPolicyModal.vue";
import TermsModal from "./TermsModal.vue";
import CharteFbModal from "./CharteFbModal.vue";
import ContactModal from "./ContactModal.vue";
import ShareLinkModal from "./ShareLinkModal.vue";
import SummaryModal from "./SummaryModal.vue";
import DiffusionEmailsModal from "./DiffusionEmailsModal.vue";
import ProductTempUnavailableModal from "./ProductTempUnavailableModal.vue";

export default defineComponent({
  name: "Modal",
  components: {
    NModal,
    RealisationModal,
    IubendaPrivacyPolicyModal,
    TermsModal,
    CharteFbModal,
    ContactModal,
    ShareLinkModal,
    SummaryModal,
    DiffusionEmailsModal,
    ProductTempUnavailableModal,
  },
  setup() {
    const initialValues = reactive({
      size: "min",
    });

    const { close, displayModal, currentModal, childrenRef } = useModal();

    const updateSize = (newSize: string): void => {
      initialValues.size = newSize || "min";
    };

    return { initialValues, currentModal, displayModal, childrenRef, close, updateSize };
  },
});
</script>
